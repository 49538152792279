import { FC } from 'react';
import clsx from 'clsx';
import { useContainer, Props } from './useContainer';
import styles from './Container.module.css';

export const Container: FC<Props> = (props) => {
  const { children, size } = useContainer(props);

  return (
    <div className={clsx(styles.root, size === 'sm' && styles.sm)}>
      {children}
    </div>);

};