import { ReactNode } from 'react'

export interface Props {
  children: ReactNode
  size?: 'sm'
}

export const useContainer = (props: Props) => {
  const { children, size } = props

  return {
    children,
    size,
  }
}
